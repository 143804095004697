var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { full: "" } },
    [
      _c("div", { staticClass: "operation" }, [
        _c("span", { staticClass: "cancel", on: { click: _vm.cancel } }, [
          _vm._v(_vm._s(_vm.$t("lang.cancel"))),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.$t("lang.newPost")))]),
        _c("span", { staticClass: "send", on: { click: _vm.send } }, [
          _vm._v(_vm._s(_vm.$t("lang.share"))),
        ]),
      ]),
      _c(
        "div",
        [
          _c("van-field", {
            attrs: {
              placeholder: _vm.$t("lang.shareTips"),
              type: "textarea",
              autosize: "",
            },
            model: {
              value: _vm.content,
              callback: function ($$v) {
                _vm.content = $$v
              },
              expression: "content",
            },
          }),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _vm.videoSrc
                ? _c("div", [
                    _c("video", {
                      staticStyle: {
                        margin: "18px 0 0 18px",
                        width: "240px",
                        height: "80px",
                      },
                      attrs: {
                        src: _vm.videoSrc,
                        controls: "",
                        "webkit-playsinline": "true",
                        playsinline: "true",
                        "x5-playsinline": "true",
                      },
                    }),
                  ])
                : _vm._e(),
              _c("van-uploader", {
                staticStyle: { margin: "18px 0 0 18px" },
                attrs: {
                  "preview-image": false,
                  accept: "video/*",
                  "after-read": _vm.afterRead,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "van-overlay",
        { attrs: { show: _vm.isLoading } },
        [
          _c(
            "van-loading",
            {
              staticClass: "loading",
              attrs: { type: "spinner", color: "#E5E5E5", vertical: "" },
            },
            [_vm._v(_vm._s(_vm.$t("lang.onTheChain")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }