<template>
  <layout full>
    <div class="operation">
      <span class="cancel" @click="cancel">{{ $t('lang.cancel') }}</span>
      <span>{{ $t('lang.newPost') }}</span>
      <span class="send" @click="send">{{ $t('lang.share') }}</span>
    </div>
    <div>
      <van-field v-model="content" :placeholder="$t('lang.shareTips')" type="textarea" autosize />
      <div style="display: flex">
        <div v-if="videoSrc">
          <video :src="videoSrc" controls
                 webkit-playsinline="true" playsinline="true" x5-playsinline="true"
                 style="margin: 18px 0 0 18px; width: 240px; height: 80px"></video>
        </div>
        <van-uploader :preview-image="false" accept="video/*" :after-read="afterRead" style="margin: 18px 0 0 18px;" />
      </div>
    </div>
    <van-overlay :show="isLoading">
      <van-loading class="loading" type="spinner" color="#E5E5E5" vertical>{{ $t('lang.onTheChain') }}</van-loading>
    </van-overlay>
  </layout>
</template>

<script>
import { Field, Uploader, Loading, Overlay} from 'vant'
import common from '@/utils/common'
import md5 from 'crypto-js/md5'
import sha256 from 'crypto-js/sha256'
import { Buffer } from 'buffer'
import { getTxDataCycle } from '@/utils/utils'
export default {
  name: "Video",
  data() {
    return {
      content: '',
      fileList: [],
      fileSize: 0,
      fileName: '',
      fileType: '',
      sliceCount: 0,
      results: [],
      videoSrc: '',
      md5Data: '',
      sha256Data: '',
      isLoading: false
    }
  },
  components: {
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    async afterRead(file) {
      let fileObj = file.file
      this.videoSrc = file.content
      this.md5Data = md5(fileObj)
      this.sha256Data = sha256(fileObj)

      let SLICE_SIZE = 102400;
      let fileSize = fileObj.size;
      this.fileSize = fileSize
      this.fileName = fileObj.name
      this.fileType = fileObj.type
      if (fileSize > SLICE_SIZE){
        // 计算需要分几份
        let sliceCount = Math.ceil(fileSize / SLICE_SIZE);
        this.sliceCount = sliceCount
        for (let i = 0; i < sliceCount; ++i) {
          // 计算分片起始位置
          let start = i * SLICE_SIZE;
          // 计算分片结束位置
          let end = start + SLICE_SIZE;
          // 最后一片特殊处理
          if (end > fileSize) {
            end = fileSize;
          }
          let newBlob = fileObj.slice(start, end);

          let promise = new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function(result) {
              let buffer = Buffer.from(reader.result)
              let hex = buffer.toString('hex')
              resolve(hex)
            }
            reader.readAsArrayBuffer(newBlob);
          })
          let hex = await promise
          if (hex) {
            this.results.push(hex)
          }
        }
        return;
      }
    },
    sendVideo (checkOnly, videoPlatformFee) {
      return new Promise((resolve, reject) => {
        if (!checkOnly) checkOnly = false
        let params = {
          nodeName: 'MetaFile',
          brfcId: 'fcac10a5ed83',
          path: '/Protocols/MetaFile',
          dataType: 'metafile/index',
          attachments: this.results.map((res, index) => {
            return {
              fileType: 'metafile/chunk',
              data: res,
              fileName: this.sha256Data + '_' + index
            }
          }),
          needConfirm: false,
          encoding: 'binary',
          version: '1.0.1',
          data: JSON.stringify({
            md5: this.md5Data + '',
            sha256: this.sha256Data + '',
            fileSize: this.fileSize,
            chunkNumber: this.sliceCount,
            chunkSize: 102400,
            dataType: this.fileType,
            name: this.fileName,
            chunkList: this.results.map((res, index) => {
              return {
                sha256: sha256(res) + '',
                txid: `![metafile](${index})`
              }
            })
          }),
          checkOnly,
          payTo: videoPlatformFee ? [{ amount: videoPlatformFee, address: process.env.VUE_APP_PlatformAddress }] : []
        }

        this.$store.state.sdk.sendMetaDataTx(params).then(res=>{
          resolve(res)
        }, (err) => {
          reject()
        })
      })
    },
    addSimplePhotoShareProtocols (params) {
      return new Promise((resolve, reject) => {
        if (!params.checkOnly) params.checkOnly = false
        let _params = {
          "createTime": Math.round(new Date()),
          "description": this.content,
          "tags": [],
          'mention': [],
          "photos": [`metafile://${params.txId}.mp4`],
        }

        const txData = common.getTxData({
          nodeName: 'SimplePhotoShare',
          brfcId: '88a4a0bdebcb',
          path: '/Protocols/SimplePhotoShare',
          data: JSON.stringify(_params),
          needConfirm: false,
          checkOnly: params.checkOnly,
          payTo: [
            {
              amount: 2000,
              address: process.env.VUE_APP_PlatformAddress
            }
          ]
        })

        this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
          resolve(res)
        }, (err) => {

        })
      })
    },

    // sleep (timer = 5000) {
    //   return new Promise(resolve => {
    //     setTimeout(() => {
    //       resolve()
    //     }, timer)
    //   })
    // },

    addSimplePhotoShare(checkOnly, videoPlatformFee) {
      return new Promise(async (resolve, reject) => {
        try {
          // 计算价格
          const usedAmount = {
            videoUsedAmount: 0,
            photoShareAmount: 0
          }
          // 视频价格
          const sendVideoRes = await this.sendVideo(checkOnly, videoPlatformFee)
          if ((sendVideoRes && sendVideoRes.code === 200) || (sendVideoRes && sendVideoRes.code === 205)) {
            if (sendVideoRes.data.usedAmount) {
              usedAmount.videoUsedAmount = parseInt(sendVideoRes.data.usedAmount)
            }

            if (!checkOnly) {
              // 获取上一条数据才继续进行，防止双花
              // await this.sleep() // 模拟
              await getTxDataCycle(sendVideoRes.data.txId)
            }

            // 帖子价格
            const simplePhotoRes = await this.addSimplePhotoShareProtocols({
              txId: sendVideoRes.data.txId,
              checkOnly
            })
            if ((simplePhotoRes && simplePhotoRes.code === 200) || (simplePhotoRes && simplePhotoRes.code === 205)) {
              if (simplePhotoRes.data.usedAmount) {
                usedAmount.photoShareAmount = parseInt(simplePhotoRes.data.usedAmount)
              }
              if (checkOnly) {
                resolve(usedAmount)
              } else {
                resolve(simplePhotoRes)
              }
            }
          }
        } catch (err) {
          reject(err)
        }
      })
    },
    async send() {
      if (this.fileSize > 10 * 1024 * 1024) {
        this.$toast('不能超过10M')
        return
      }
      if (!this.content && this.fileSize == 0) {
        this.$toast(this.$t('lang.emptyContent'))
        return
      }
      this.isLoading = true
      // 获取价格
      try {
        const usedAmount = await this.addSimplePhotoShare(true)
        this.isLoading = false
        if (usedAmount) {
          const videoPlatformFee = Math.ceil(usedAmount.videoUsedAmount * 0.05)
          const totalUsedAmount = usedAmount.videoUsedAmount + videoPlatformFee + usedAmount.photoShareAmount
          const getBalanceRes = await this.$store.state.sdk.getBalance()
          if (getBalanceRes && getBalanceRes.code === 200) {
            if (parseInt(getBalanceRes.data.satoshis) > totalUsedAmount) {
              // 够钱支付
              this.$dialog.confirm({
                title: '提示',
                message: `需要支付 ${totalUsedAmount} STAS`,
              }).then(async () => {
                this.$toast({
                  message: '正在上链，请耐心等待...',
                  position: 'bottom',
                  forbidClick: true,
                  duration: 0
                })

                //确认支付
                const res = await this.addSimplePhotoShare(false, videoPlatformFee)
                if (res && res.code === 200) {
                  this.$toast.clear()
                  this.$toast(this.$t('lang.success'))
                  this.$router.back()
                }
              });
            } else {
              // 不够钱
              this.$dialog.confirm({
                title: '提示',
                message: `余额不足，需要支付 ${totalUsedAmount} STAS`,
              })
            }
          }
        }
      } catch (error) {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.operation {
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  border-bottom: 1px solid #C4C5C6;
  .cancel {
    cursor: pointer;
  }
  .send {
    font-size: 14px;
    color: #FFFFFF;
    background-color: #617FFF;
    width: 60px;
    height: 30px;
    border-radius: 6px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }
}
/deep/.van-field__control {
  font-size: 18px;
}
/deep/.van-loading {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>